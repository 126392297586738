import React, { useState } from "react"
import "./ContactForm.css"
import { SendContactForm } from "../../../requests/SendContactForm"
import BackgroundImageArrowRightLarge from "./BackgroundImageArrowRightLarge"
import { BgImage } from "gbimage-bridge"
function ContactForm() {
  const arrowRightLargeImage = BackgroundImageArrowRightLarge()

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [sending, setSending] = useState(false)
  const [sendingError, setSendingError] = useState(false)
  const [sendingSuccess, setSendingSuccess] = useState(false)

  const nameChange = e => {
    setName(e.target.value)
  }
  const emailChange = e => {
    setEmail(e.target.value)
  }
  const messageChange = e => {
    setMessage(e.target.value)
  }

  const successMessage = () => {
    setSending(false)
    setSendingSuccess(true)
    setName("")
    setEmail("")
    setMessage("")
  }

  const sendMessage = async e => {
    e.preventDefault()
    setSending(true)
    let sendResult = await SendContactForm(name, email, message)
    sendResult ? successMessage() : setSendingError(true)
  }

  const sendingSpinner = () => {
    return (
      <div className="contact-form__container__background-image__form-container__submit">
        Sending
      </div>
    )
  }

  const sendingErrorMessage = () => {
    return (
      <div className="contact-form__container__background-image__form-container__submit">
        Error sending message please email me at dan.roberts@nonstopcoding.co.uk
      </div>
    )
  }
  const sendingSuccessMessage = () => {
    return (
      <div className="contact-form__container__background-image__form-container__submit">
        Thanks I'll be in touch...
      </div>
    )
  }

  const submitButton = () => {
    return (
      <button
        className="contact-form__container__background-image__form-container__submit"
        onClick={e => {
          sendMessage(e)
        }}
      >
        Submit >
      </button>
    )
  }

  return (
    <div className="card contact-form__container">
      <BgImage
        image={arrowRightLargeImage}
        className="contact-form__container__background-image"
        style={{
          backgroundSize: "contain",
          backgroundPosition: "left",
          minHeight: "100%",
          width: "100%",
        }}
      >
        <div className="contact-form__container__background-image__form-container">
          <h3 className="contact-form__container__background-image__h3">
            How can I help you ?
          </h3>
          <input
            className="contact-form__container__background-image__form-container__input"
            type="text"
            value={name}
            name="name"
            placeholder="Name?"
            onChange={e => {
              nameChange(e)
            }}
          ></input>
          <input
            className="contact-form__container__background-image__form-container__input"
            type="text"
            value={email}
            name="email"
            placeholder="Email?"
            onChange={e => {
              emailChange(e)
            }}
          ></input>
          <textarea
            className="contact-form__container__background-image__form-container__input contact-form__container__background-image__form-container__input--message"
            value={message}
            name="message"
            placeholder="Message?"
            onChange={e => {
              messageChange(e)
            }}
          ></textarea>
          {sending
            ? sendingSpinner()
            : sendingError
            ? sendingErrorMessage()
            : sendingSuccess
            ? sendingSuccessMessage()
            : submitButton()}
        </div>
      </BgImage>
    </div>
  )
}

export default ContactForm
