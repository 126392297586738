export const SendContactForm = (name, email, message) => {
  const data = {
    name: name,
    email: email,
    message: message,
  }

  const postResult = fetch("/utilities/php-mailer/ContactFormEmail.php", {
    body: JSON.stringify(data),
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "no-cors", // no-cors, cors, *same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then(res => {
    if (res.ok) {
      console.log(res)
      return true
    }
  })

  return postResult
}
